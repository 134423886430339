import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "RegistrationPoliciesPage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "header-left" }
const _hoisted_4 = { class: "bottomSpace" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "policy-type-section"
}
const _hoisted_7 = { class: "policy-header" }
const _hoisted_8 = { class: "header-left" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { id: "userPolicyAgree" }
const _hoisted_13 = { for: "userPolicy" }
const _hoisted_14 = { class: "button-section-right" }
const _hoisted_15 = {
  class: "button-right submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('reg_title_acknowledgements')), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('reg_instructions_acknowledgements')), 1),
        (_ctx.htmlErrorMessage)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "alertBox bottomSpace",
              innerHTML: _ctx.htmlErrorMessage
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.SaveUserPolicies && _ctx.SaveUserPolicies(...args)))
        }, [
          (_ctx.currentUserPolicy.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h4", _hoisted_8, _toDisplayString(_ctx.currentUserPolicy.header), 1),
                  (_ctx.ShowFlag(_ctx.currentUserPolicy.type))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("img", {
                          src: _ctx.GetFlag(_ctx.currentUserPolicy.countryCode),
                          alt: "country flag"
                        }, null, 8, _hoisted_10),
                        _createTextVNode(" ")
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", {
                  innerHTML: _ctx.currentUserPolicy.description
                }, null, 8, _hoisted_11),
                _createElementVNode("div", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "form-check-input",
                    name: "userPolicy",
                    id: "userPolicy",
                    value: "true",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentUserPolicy.consented) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, _ctx.currentUserPolicy.consented]
                  ]),
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.currentUserPolicy.consentLabel), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", _hoisted_15, _toDisplayString(_ctx.$t('confirm_acceptance')), 1),
            _createElementVNode("button", {
              class: "go-back button-right",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
            }, _toDisplayString(_ctx.$t('cancel')), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}