import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "AppListItem col" }
const _hoisted_2 = { class: "col-content" }
const _hoisted_3 = { class: "appTitle" }
const _hoisted_4 = { class: "appLogo" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "appName" }
const _hoisted_7 = { class: "appDesc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.app.iconUrl,
            alt: _ctx.pic
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.app.name), 1)
      ]),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.app.description), 1),
      _createElementVNode("button", {
        "data-testid": "{{ app.name.replace(' ', '_') }}",
        class: "button",
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.LoadApp()))
      }, _toDisplayString(_ctx.$t('launch')), 1)
    ])
  ]))
}