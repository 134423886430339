<template>
  <div>
    <CwePageHeader :authenticationRequired="true"  />

    <div id="RegistrationPoliciesPage" class="shared-page">

      <div class="centeredContentBox">

        <h3 class="header-left">{{ $t('reg_title_acknowledgements') }}</h3>
        <p class="bottomSpace">{{ $t('reg_instructions_acknowledgements') }}</p>

        <div v-if="htmlErrorMessage" class="alertBox bottomSpace" v-html="htmlErrorMessage"></div>

        <form @submit="SaveUserPolicies">

          <div class="policy-type-section" v-if="currentUserPolicy.id">
            <div class="policy-header">
              <h4 class="header-left">{{ currentUserPolicy.header }}</h4>
              <div v-if="ShowFlag(currentUserPolicy.type)"><img :src="GetFlag(currentUserPolicy.countryCode)" alt="country flag">&nbsp;</div>
            </div>
            <div v-html="currentUserPolicy.description"></div>
            <div id="userPolicyAgree">
                <input type="checkbox" class="form-check-input" name="userPolicy" id="userPolicy" value="true" v-model="currentUserPolicy.consented">
                <label for="userPolicy">{{ currentUserPolicy.consentLabel }}</label>
            </div>
          </div>

          <div class="button-section-right">
              <button class="button-right submit" type="submit">{{ $t('confirm_acceptance') }}</button>
            <button class="go-back button-right" @click="cancel()">{{ $t('cancel') }}</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
  import CwePageHeader from '../components/CwePageHeader.vue';
  import { store } from '@/store/store';
  import * as Vue from 'vue';
  import WaitForAppDependencies from '../services/AppDependencyService';
  import { SignUserPolicies, GetUserPolicies } from "@/api/endpoints/policyWeb";
  import { SignUserPoliciesRequest, UserPolicySignOff, UserPolicy } from '@/types/webContracts';
  import LoadAuthStatusAsync from '../services/AuthStatusLoader';
  import GetCountryFlagUrl from '../services/CountryFlag';

  export default Vue.defineComponent({
    name: 'RegistrationPoliciesPage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
        errorDisplayKey: '',
        htmlErrorMessage: '',
        userPolicies: [] as UserPolicy[],
        currentPolicyIndex: -1
      }
    },
    mounted: async function () {
        await this.LoadUserPolicies();
    },
    provide() {
      return {

      }
    },

    created: async function () {
      await WaitForAppDependencies();
    },
    computed: {
      currentUserPolicy(): UserPolicy {
        return this.userPolicies?.length > 0 && this.currentPolicyIndex >= 0 ? this.userPolicies[this.currentPolicyIndex] : {} as UserPolicy;
      }
    },
    methods: {        
      LoadUserPolicies: async function () {
        this.userPolicies.splice(0, this.userPolicies.length);    // clear model

        const result = await GetUserPolicies();

        if(result.status==403 || result.status==401){
          this.$router.push('prelogin');
        }else if(result?.data?.errors!=null && result.data.errors.length>0){
          this.htmlErrorMessage = result.data.errors[0].localizedName ?? this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }else if(result.data?.policies!=null){

          if (result.data.policies?.length > 0) {
            const policies = result.data.policies.filter(p => !p.consented && p.required);

            const firstPolicyTypeToShow = 'UserPolicy_End_User_License_Agreement';
            const sortedPolicies = policies.sort(function (x, y) { return x.type == firstPolicyTypeToShow ? -1 : y.type == firstPolicyTypeToShow ? 1 : 0; });

            this.userPolicies = sortedPolicies;
            this.currentPolicyIndex = 0;
          } else {
            this.currentPolicyIndex = -1;
          }

        } else { 
          this.htmlErrorMessage = this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }
      },
      SaveUserPolicies: async function (e: any) {
        e.preventDefault();
        this.ClearErrors();

        const userPolicy = this.currentUserPolicy;
        if (!userPolicy.consented) {
          this.htmlErrorMessage = this.$t('error_must_agree_to_required_policy');
          return;
        }

        const signOff: UserPolicySignOff = {
          PolicyType: userPolicy.type,
          CountryCode: userPolicy.countryCode,
          OptOut: !userPolicy.consented
        };

        const policyRequest: SignUserPoliciesRequest = { 
          signOffs: [signOff]
        };

        store.state.loadingCount++;
        const result = await SignUserPolicies(policyRequest);
        await LoadAuthStatusAsync(false, true);
        store.state.loadingCount--;

        if(result.status==403 || result.status==401){
          this.$router.push('prelogin');
        }else if(result?.data?.errors!=null && result.data.errors.length>0){
          this.htmlErrorMessage = result.data.errors[0].localizedName ?? this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }else if(result.status!=200){
          this.htmlErrorMessage = this.$t('unexpected_error');
          window.scrollTo(0, 0);
        }else{
          this.NextPolicy();
        }

      },
      NextPolicy() {
        if(this.currentPolicyIndex < (this.userPolicies.length-1) ) {
          this.currentPolicyIndex++;
        }else{
          this.$router.push('/');
        }
      },
      cancel: function () {
        this.$router.push('Prelogin');
      },      
      ClearErrors: function () {
        this.htmlErrorMessage = '';
      },
      ShowFlag: function (policyType: string) {
        if (!policyType) return false;

        return this.userPolicies.filter(u => u.type == policyType && u.countryCode?.length > 0).length > 1;
      },
      GetFlag: function (countryCode: string) {
        return GetCountryFlagUrl(countryCode);
      }
    },
  })
</script>

<style lang="scss">
#RegistrationPoliciesPage { 
  text-align: left;

  .centeredContentBox {
      background-color: $light_gray;
      width: 80%;
      max-width: $maxInnerPageWidth;

      #userPolicyAgree {
        margin-top: $standard_space * 2;
      }
  }

  .policy-type-section { 
    margin-bottom: $standard_space;
  }

  .policy-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img {
      height: 23px;
      margin: 5px;
    }
  }
}
</style>
