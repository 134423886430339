import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "EditProfile" }
const _hoisted_2 = { class: "centeredContentBox" }
const _hoisted_3 = { class: "back-section" }
const _hoisted_4 = { class: "back-section-inner" }
const _hoisted_5 = { class: "heading" }
const _hoisted_6 = {
  class: "header-left",
  role: "heading"
}
const _hoisted_7 = {
  key: 0,
  class: "bottomSpace"
}
const _hoisted_8 = {
  key: 0,
  class: "alertBox bottomSpace"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "column" }
const _hoisted_12 = { class: "section" }
const _hoisted_13 = { class: "label" }
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "optional" }
const _hoisted_16 = { class: "nameRow" }
const _hoisted_17 = { class: "firstNameColumn" }
const _hoisted_18 = { class: "section" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = ["for"]
const _hoisted_21 = {
  key: 0,
  class: "error"
}
const _hoisted_22 = { class: "lastNameColumn" }
const _hoisted_23 = { class: "section" }
const _hoisted_24 = { class: "label" }
const _hoisted_25 = ["for"]
const _hoisted_26 = {
  key: 0,
  class: "error"
}
const _hoisted_27 = { class: "section" }
const _hoisted_28 = { class: "label" }
const _hoisted_29 = ["for"]
const _hoisted_30 = { class: "optional" }
const _hoisted_31 = { class: "section" }
const _hoisted_32 = { class: "label" }
const _hoisted_33 = ["for"]
const _hoisted_34 = { class: "optional" }
const _hoisted_35 = { class: "column" }
const _hoisted_36 = { class: "section" }
const _hoisted_37 = {
  key: 0,
  class: "error"
}
const _hoisted_38 = { class: "section" }
const _hoisted_39 = { class: "label" }
const _hoisted_40 = ["for"]
const _hoisted_41 = { key: 0 }
const _hoisted_42 = { key: 1 }
const _hoisted_43 = {
  key: 2,
  class: "error"
}
const _hoisted_44 = {
  key: 2,
  id: "contact-admin"
}
const _hoisted_45 = { class: "contact" }
const _hoisted_46 = { class: "button-section-right" }
const _hoisted_47 = {
  class: "button-right submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_PhoneNumberInput = _resolveComponent("PhoneNumberInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, { authenticationRequired: false }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-angle-left" })
            ])
          ])
        ]),
        _createElementVNode("form", {
          onSubmit: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.submitUser && _ctx.submitUser(...args)))
        }, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.user.FederatedTenant === false)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  id: "changePasswordButton",
                  class: "button-right button-secondary",
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)))
                }, _toDisplayString(_ctx.$t('change_password')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('my_profile')), 1)
          ]),
          (_ctx.messageDisplayKey == this.$t('edit_profile_changes_saved'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.messageDisplayKey)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t(_ctx.messageDisplayKey)), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("a", {
                  href: "#",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.returnToDashboard()))
                }, _toDisplayString(_ctx.$t('return_dashboard')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.htmlErrorMessage)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "alertBox bottomSpace",
                innerHTML: _ctx.htmlErrorMessage
              }, null, 8, _hoisted_9))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", {
                    for: _ctx.user.Prefix
                  }, _toDisplayString(_ctx.$t('prefix')), 9, _hoisted_14),
                  _createElementVNode("span", _hoisted_15, " - " + _toDisplayString(_ctx.$t('optional')), 1)
                ]),
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "prefixInput editProfileInput",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.Prefix) = $event)),
                    placeholder: "prefix_placeholder"
                  }, null, 512), [
                    [_vModelText, _ctx.user.Prefix]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("label", {
                        for: _ctx.user.FirstName
                      }, _toDisplayString(_ctx.$t('first_name')), 9, _hoisted_20)
                    ]),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "firstNameInput editProfileInput",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.FirstName) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.user.FirstName]
                      ])
                    ]),
                    (_ctx.firstNameError.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.firstNameError), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("label", {
                        for: _ctx.user.LastName
                      }, _toDisplayString(_ctx.$t('last_name')), 9, _hoisted_25)
                    ]),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        class: "lastNameInput editProfileInput",
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.LastName) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.user.LastName]
                      ])
                    ]),
                    (_ctx.lastNameError.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.lastNameError), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("label", {
                    for: _ctx.user.Suffix
                  }, _toDisplayString(_ctx.$t('suffix')), 9, _hoisted_29),
                  _createElementVNode("span", _hoisted_30, " - " + _toDisplayString(_ctx.$t('optional')), 1)
                ]),
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "suffixInput editProfileInput",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.Suffix) = $event)),
                    placeholder: "suffix_placeholder"
                  }, null, 512), [
                    [_vModelText, _ctx.user.Suffix]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("label", {
                    for: _ctx.user.Npi
                  }, _toDisplayString(_ctx.$t('npi')), 9, _hoisted_33),
                  _createElementVNode("span", _hoisted_34, " - " + _toDisplayString(_ctx.$t('optional')), 1)
                ]),
                _createElementVNode("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    class: "npiInput editProfileInput",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.Npi) = $event)),
                    placeholder: "npi_placeholder",
                    maxlength: "100"
                  }, null, 512), [
                    [_vModelText, _ctx.user.Npi]
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _createVNode(_component_PhoneNumberInput, {
                  phoneNumberLabel: this.$t('mobile_number'),
                  noMatchingCountriesLabel: this.$t('no_matching_countries'),
                  phoneCountryCodes: this.phoneCountryCodes,
                  phoneCountryCode: this.selectedCountryCode,
                  phoneNumber: this.user.Phone,
                  onUpdatePhoneCountryCode: _cache[8] || (_cache[8] = ($event: any) => (_ctx.phoneCountryCodeChanged($event))),
                  onUpdatePhoneNumber: _cache[9] || (_cache[9] = ($event: any) => (_ctx.phoneNumberChanged($event))),
                  onUpdatePhoneNumberError: _cache[10] || (_cache[10] = ($event: any) => (_ctx.phoneNumberErrorChanged($event)))
                }, null, 8, ["phoneNumberLabel", "noMatchingCountriesLabel", "phoneCountryCodes", "phoneCountryCode", "phoneNumber"]),
                (_ctx.phoneNumberError.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, _toDisplayString(_ctx.phoneNumberError), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("label", {
                    for: _ctx.user.Email
                  }, _toDisplayString(_ctx.$t('email_address')), 9, _hoisted_40)
                ]),
                (_ctx.user.FederatedTenant === true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_ctx.user.Email), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_42, [
                      _withDirectives(_createElementVNode("input", {
                        class: "emailInput editProfileInput",
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.Email) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.user.Email]
                      ])
                    ])),
                (_ctx.user.FederatedTenant === false && _ctx.emailError.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_ctx.emailError), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          (_ctx.user.FederatedTenant === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.$t('contact_admin_for_changes')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_46, [
            _createElementVNode("button", _hoisted_47, _toDisplayString(_ctx.$t('save_profile')), 1),
            _createElementVNode("button", {
              class: "button-right go-back",
              type: "button",
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.cancel()))
            }, _toDisplayString(_ctx.$t('cancel')), 1)
          ])
        ], 32)
      ])
    ])
  ]))
}