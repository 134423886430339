import { ApiRoutes } from '@/api/ApiRoutes';
import axios from 'axios';
import { store } from '../store/store';
import TimezoneOffset from './TimezoneOffset';

interface AppLoginTokenResponse {
  token: string;
  countryCode: string;
}

export default {

  Go(appRedirectUrl: string, appRedirectKey: string, useTarget: boolean, path?: string) {
    store.state.loadingCount++;
    const cacheBuster = Date.now();

    axios.get(ApiRoutes.Auth.GetAntiForgeryToken+"?t="+cacheBuster).then(() => {
      const requestToken = this.GetCookieValue("XSRF-REQUEST-TOKEN");
      
      axios.post(ApiRoutes.Auth.GetAuthAppRedirectToken+"?t="+cacheBuster,
        {
          "__RequestVerificationToken": requestToken,
          "AppKey":appRedirectKey
        },
        {
          headers: {
            "X-XSRF-TOKEN": requestToken
          }
      }).then((response) => {
        const appLoginResponse = response.data as AppLoginTokenResponse;
        
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = appRedirectUrl;

        if (appRedirectKey && appRedirectKey.length > 0 && useTarget)
          form.target = appRedirectKey;

        form.setAttribute('style', 'display:none;');

        const tokenEl = document.createElement('input');
        tokenEl.value = appLoginResponse.token;
        tokenEl.name = 'token';
        form.appendChild(tokenEl);
        
        const utcOffsetEl = document.createElement('input');
        utcOffsetEl.value = TimezoneOffset().toString();
        utcOffsetEl.name = 'utcOffset';
        form.appendChild(utcOffsetEl);
        
        const userAgentEl = document.createElement('input');
        userAgentEl.value = navigator.userAgent;
        userAgentEl.name = 'userAgent';
        form.appendChild(userAgentEl);
        
        const countryCodeEl = document.createElement('input');
        countryCodeEl.value = appLoginResponse.countryCode;
        countryCodeEl.name = 'countryCode';
        form.appendChild(countryCodeEl);
        
        const regionalDomainEl = document.createElement('input');
        regionalDomainEl.value = window.location.origin;
        regionalDomainEl.name = 'regionalDomain';
        form.appendChild(regionalDomainEl);
        
        if (typeof path !== 'undefined') {
            const pathEl = document.createElement('input');
            pathEl.value = path;
            pathEl.name = 'path';
            form.appendChild(pathEl);
        }
        
        document.body.appendChild(form);
        window.setTimeout(function(){
          form.submit();
        },100);

      }).catch(function (error) {
        store.state.loadingCount = 0;
        console.log(error);

        if (error?.response?.status == 403)
          window.location.href = '/prelogin';
        else
          window.location.href = '/error?errorKey=unexpected';

      }).finally(() => {

      });
    }).catch(function (error) {
      store.state.loadingCount = 0;

      if (error?.response?.status == 403)
        window.location.href = '/prelogin';
      else
        window.location.href = '/error?errorKey=unexpected';

    }).finally(() => {
      store.state.loadingCount--;
    });
  },
  GetCookieValue(cookieName: string) {
    const allCookies = decodeURIComponent(document.cookie).split('; ');
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[ i ];
      if (cookie.startsWith(cookieName + '=')) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return '';
  }
};