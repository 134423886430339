import { debounce } from 'lodash';
import router from '@/router'
import { sessionModule } from '@/store';
import { store } from '@/store/store';
import EventBus from '@/services/EventBus';
import FixProxyUrl from './FixProxyUrl';

let idleLogoutTimer: any = null;

function setUpDebounce() {
  const inactiveTimeout = (store.state.cookieExpireMinutes * 60 * 1000);  // in milliseconds

  idleLogoutTimer = debounce(invokeLogout, inactiveTimeout);// throttles events.
}

async function invokeLogout() {
  window.location.href = FixProxyUrl('/logout');
  EventBus.emit('user-logged-out');
}

export function setupInactivityRedirect() {
  setUpDebounce();  
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
    document.addEventListener(event, idleLogoutTimer);
  });
}

function teardownInactivityRedirect() {
  if (idleLogoutTimer != null) {
    idleLogoutTimer.cancel();  
  }  
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
    document.removeEventListener(event, idleLogoutTimer);
  });
}

export function handleLogout(redirect: string | undefined) {
  teardownInactivityRedirect()
  sessionModule.clear()
  
  if (redirect) {
    router.push({ name: redirect })
  }
}
