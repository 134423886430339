import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "PolicyPage",
  class: "shared-page"
}
const _hoisted_2 = { class: "centeredContentBox centeredContentBoxMedia" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "policy-section"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CwePageHeader = _resolveComponent("CwePageHeader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CwePageHeader, {
      authenticationRequired: true,
      isApp: _ctx.isApp == true
    }, null, 8, ["isApp"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.htmlErrorMessage)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "alertBox bottomSpace",
              innerHTML: _ctx.htmlErrorMessage
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h4", null, _toDisplayString(_ctx.policyHeader), 1),
              _createElementVNode("div", { innerHTML: _ctx.policyText }, null, 8, _hoisted_5)
            ]))
      ])
    ])
  ]))
}