import axios from 'axios';
import { store, sessionModule } from '../store'
import { IAuthStatusResponse } from '../models/AuthStatusResponse'
import { ApiRoutes } from '@/api/ApiRoutes';
import EventBus from './EventBus';


/// Load user auth info from zbedge api, including logged in status, apps, etc. 
export default async function LoadAuthStatusAsync(showLoading:boolean, forceRefresh:boolean){

  // Only call this once then cache results, unless forced to refresh  
  if(!forceRefresh && store.state.initiated) 
    return null;

  store.state.loadingCount++;
  store.state.authLoading = true;
  const cacheBuster = Date.now();
    
  await axios.get(ApiRoutes.Auth.GetAuthStatus+"?t="+cacheBuster).then((response) => {

    const authStatus = response.data as IAuthStatusResponse;

    store.state.fullName = authStatus.fullName;

    if (authStatus.userLanguageCode)
      store.state.browserLocale = authStatus.userLanguageCode;

    store.state.loggedIn = authStatus.loggedIn;
    store.state.apiServerAvailable = true;
    store.state.matchingUserFound = authStatus.matchingUserFound;
    store.state.apps = authStatus.apps.filter(el => el.enabled);
    store.state.moreApps = authStatus.apps.filter(el => !el.enabled);
    sessionModule.setPolicies(authStatus.policies);

    EventBus.emit('user-authenticated');

  }).catch(function (error) {
    store.state.error = error;
  }).finally(()=>{
    store.state.authLoading = false;
    store.state.loadingCount--;
    store.state.initiated = true;
  });
}