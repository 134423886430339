import { ZBEdgeState } from '@/types/storeTypes'
import Vuex from 'vuex'

export const store = new Vuex.Store<ZBEdgeState>({
  state: {
    apps: [],
    browserLocale: '',
    fullName: '',
    initiated: false,
    authLoading: false,
    appDependenciesLoaded: false,
    redirectBeforeRouteEnter: false,
    loadingCount: 0,
    loggedIn: false,
    apiServerAvailable: false,
    matchingUserFound: false,
    skipPortalLandingPage: false,
    federatedTenantRequiredForZBEmployees: false,
    alwaysShowMigrationMessage: true,
    moreApps: [],
    userAvatarUrl: '',
    userId: null,
    aboutUrl: '',
    supportUrl: '',
    passwordMinLength: 0,
    error: null,
    appRedirect: '',
    cookieExpireMinutes: 0
  }
})